import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Datoimagename = ({ name, alt, folder }) => (
  <StaticQuery
    query={graphql`
      {
        datoCmsImage {
          topimage {
            url
            filename
            pc: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
            sp: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "1200" }) {
              ...GatsbyDatoCmsFluid
            }
            news: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "800" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          contentsimg {
            url
            filename
            pc: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
            sp: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "1200" }) {
              ...GatsbyDatoCmsFluid
            }
            news: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "800" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          productsImg {
            url
            filename
            pc: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
            sp: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "1200" }) {
              ...GatsbyDatoCmsFluid
            }
            news: fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "800" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.datoCmsImage;
      const image = [];

      if (folder === "topimage") {
        imageData.topimage.forEach((e) => {
          image[e.filename] = [e.pc, { ...e.sp, media: `(max-width: 0px)` }];
        });
      } else if (folder === "contentsimg") {
        imageData.contentsimg.forEach((e) => {
          image[e.filename] = [e.pc, { ...e.sp, media: `(max-width: 0px)` }];
        });
      } else if (folder === "productsImg") {
        imageData.productsImg.forEach((e) => {
          image[e.filename] = [e.pc, { ...e.sp, media: `(max-width: 0px)` }];
        });
      } else {
        imageData.topimage.forEach((e) => {
          image[e.filename] = [e.pc, { ...e.sp, media: `(max-width: 0px)` }];
        });
      }

      return (
        <>
          <Img fluid={image[name]} alt={alt} />
        </>
      );
    }}
  />
);

export default Datoimagename;
