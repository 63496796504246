import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const DatoIntellectualproperty = () => (
  <StaticQuery
    query={graphql`
      {
        allDatoCmsNews(filter: { category: { slug: { eq: "intellectualproperty" } } }, sort: { order: DESC, fields: data }) {
          nodes {
            url
            title
            data(formatString: "YYYY/MM/DD")
            category {
              title
            }
            meta {
              createdAt(formatString: "YYYY/MM/DD")
              updatedAt(formatString: "YYYY/MM/DD")
            }
          }
        }
        datoCmsImage {
          topimage {
            url
            pc: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
            sp: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "1200" }) {
              ...GatsbyDatoCmsFluid
            }
            news: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "800" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          contentsimg {
            url
            pc: fluid(maxWidth: 1040, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
            sp: fluid(maxWidth: 1040, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "1200" }) {
              ...GatsbyDatoCmsFluid
            }
            news: fluid(maxWidth: 1040, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "800" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    `}
    render={(data) => {
      const newsDataIntel = data.allDatoCmsNews.nodes;
      console.log(newsDataIntel);
      return (
        <>
          <section>
            <div class="sec_chizai">
              <div class="top2401__common_hx01 top2401_contswrap01">
                <div class="area_warp">
                  <div class="box_hx">
                    <h2>
                      <span class="char_first">知</span>
                      <span class="char_rest">財情報</span>
                    </h2>
                  </div>
                </div>
                {/*<!-- ↓↓↓/.top2401__common_hx01 -->*/}
              </div>

              <div class="area_wrap01 top2401_contswrap01">
                {newsDataIntel.map((e, index) => {
                  return (
                    <div class="box_item01" key={index}>
                      <div class="inr_data">
                        <span class="data">{e.data}</span>
                      </div>
                      <div class="inr_text">{e.title}</div>
                    </div>
                  );
                })}
              </div>
              {/*<!-- /.area_wrap01 -->*/}
            </div>
            {/*<!-- /.sec_chizai -->*/}
          </section>
        </>
      );
    }}
  />
);

export default DatoIntellectualproperty;
