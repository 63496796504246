import * as React from "react";
// import Slider from "react-slick";
import Layout from "../components/layout";
// import Topmovie from "../components/topmovie"

import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

// import Datoimage from "../components/datoimage"
import DatoIntellectualproperty from "../components/datoIntellectualproperty";
import Datoimagename from "../components/datoimagename";

//topのcssをページ別に個別読み込み
import "../styles/style_top.css";

const IndexPage = ({ data }) => {
  const pageData = {
    titleClass: "top",
    title: "AVIELAN",
  };
  const divStyle = {
    padding: "56.25% 0 0 0",
    position: "relative",
  };
  const divStyle2 = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  const divStyle3 = {
    padding: "150% 0 0 0",
    position: "relative",
  };
  // const settings = {
  //   arrows: false,
  //   autoplay: true,
  //   autoplaySpeed: 4000,
  //   infinite: true,
  //   speed: 500,
  //   fade: true,
  // };
  const newsData = data.allDatoCmsNews.nodes;
  const imageData = data.datoCmsImage;
  const image = [];
  imageData.topimage.forEach((e, key) => {
    image[key] = [e.pc, { ...e.sp, media: `(max-width: 768px)` }];
  });
  // const image2 = [
  //   imageData.topimage[0].pc,{
  //     ...imageData.topimage[0].sp,
  //     media: `(max-width: 768px)`,
  //   },
  // ]

  return (
    <Layout pagestyle={pageData}>
      <section>
        <div class="sec_mv">
          <div class="area_movie">
            <div class="top2401__embed-container view_sp_this">
              <iframe src="https://player.vimeo.com/video/565968531?background=1&muted=1&autoplay=1&loop=1&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="nagoyako"></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            <div class="top2401__embed-container view_pc_this">
              <iframe src="https://player.vimeo.com/video/565951736?background=1&muted=1&autoplay=1&amp;loop=1&amp;title=0&amp;byline=0&amp;portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="nagoyako"></iframe>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>

            <div class="area_overlay"></div>
            <div class="area_leadimg top2401_contswrap01 tO t0_fadein01">
              <img class="view_pc_this" src="https://www.datocms-assets.com/45449/1702951128-sec_mv_img_lead_pc.svg" alt="- Avielan Philosophy -小さな炎も数を増やせば明るくできる。手を取り合って未来を灯す。そんな光になれたら。" />
              <img class="view_sp_this" src="https://www.datocms-assets.com/45449/1702951131-sec_mv_img_lead_sp.svg" alt="- Avielan Philosophy -小さな炎も数を増やせば明るくできる。手を取り合って未来を灯す。そんな光になれたら。" />
            </div>
          </div>
          {/*<!-- /.sec_mv -->:*/}
        </div>
      </section>

      <section>
        <div class="sec_business">
          <div class="top2401_contswrap01">
            <div class="top2401__common_hx01">
              <div class="area_warp cl_business">
                <div class="box_hx">
                  <h2>
                    <span class="char_first">世</span>
                    <span class="char_rest">界を繋ぐ</span>
                  </h2>
                </div>
                <div class="box_sub">アヴィエランの取り組み</div>
              </div>
              {/*<!-- ↓↓↓/.top2401__common_hx01 -->*/}
            </div>

            <div class="area_wrap_par top2401_contswrap01">
              <div class="area_wrap_chil">
                <div class="box_item">
                  <div class="box_img">
                    <Datoimagename name="sec_business_img01.jpg" alt="物流機器事業" folder="topimage" />
                  </div>

                  <div class="inr_wrap">
                    <div class="hx">物流機器事業</div>
                    <div class="text">
                      <p>物流の現場で使用されるスリングベルト・ラッシングベルトなど資材の製造・販売。</p>
                      <p>また特注品の製造・新商品の開発を行い、新たな事業展開を目指しております。</p>
                    </div>
                    <div class="box_btn">
                      <Link to="/logisticsequipment/">
                        <div class="top2401__common_link01">
                          もっと詳しく
                          <span class="img_arrow">
                            <img src="https://www.datocms-assets.com/45449/1702951185-top2401_img_arrow01.svg" />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {/*<!-- ↓↓↓/.box_item-->*/}
                </div>

                <div class="area_bnr_sp">
                  <a href="https://www.slingbelt.com/" target="_blank" rel="noreferrer">
                    <Datoimagename name="top2401_bnr_online_sp.jpg" alt="アヴィエラン メーカー直営オンラインストア" folder="topimage" />
                  </a>
                </div>
                {/*<!-- /.area_bnr -->*/}

                <div class="box_item cl_rev">
                  <div class="box_img">
                    <Datoimagename name="sec_business_img02.jpg" alt="ソフトウェア事業" folder="topimage" />
                  </div>

                  <div class="inr_wrap">
                    <div class="hx">ソフトウェア事業</div>
                    <div class="text">
                      <p>システム・アプリケーションの企画・開発・デザイン・保守運用まで網羅的にご提供いたします。</p>
                      <p>マッチングWEBサービス、ECサイト、顧客/在庫/業務管理、SNS/コミュニティサイトなど、技術と経験を持って柔軟にご提案します。</p>
                    </div>
                    <div class="box_btn">
                      <a href="/software-division/">
                        <div class="top2401__common_link01">
                          もっと詳しく
                          <span class="img_arrow">
                            <img src="https://www.datocms-assets.com/45449/1702951185-top2401_img_arrow01.svg" />
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/*<!-- ↓↓↓/.box_item-->*/}
                </div>

                <div class="box_item">
                  <div class="box_img">
                    <Datoimagename name="sec_business_img03.jpg" alt="ECソリューション事業" folder="topimage" />
                  </div>

                  <div class="inr_wrap">
                    <div class="hx">ECソリューション事業</div>
                    <div class="text">
                      <p>事業計画の立案、サーバー・サイトの構築、コンテンツ作成、メルマガ配信などの更新・運営。</p>
                      <p>そして解析・改善などのマーケティング支援まで、ECサイトの発展に向けて、お客様をサポートします。</p>
                    </div>
                    <div class="box_btn">
                      <a href="/ec-solution-division/">
                        <div class="top2401__common_link01">
                          もっと詳しく
                          <span class="img_arrow">
                            <img src="https://www.datocms-assets.com/45449/1702951185-top2401_img_arrow01.svg" />
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/*<!-- ↓↓↓/.box_item-->*/}
                </div>

                <div class="box_item cl_rev">
                  <div class="box_img">
                    <Datoimagename name="sec_business_img04.jpg" alt="IoTソリューション事業" folder="topimage" />
                  </div>
                  <div class="inr_wrap">
                    <div class="hx">IoTソリューション事業</div>
                    <div class="text">
                      <p>LPWAを利用した世界規模でのカゴ車・コンテナ・パレット等の物流資材の位置を可視化し、業務効率化、コスト削減を支援します。</p>
                      <p>これにより物流資材の紛失や滞留、偏在を抑止。追加購入コスト、管理コスト、輸送コストの削減に貢献できます。</p>
                    </div>
                    <div class="box_btn">
                      <a href="/iot-division/">
                        <div class="top2401__common_link01">
                          もっと詳しく
                          <span class="img_arrow">
                            <img src="https://www.datocms-assets.com/45449/1702951185-top2401_img_arrow01.svg" />
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/*<!-- ↓↓↓/.box_item-->*/}
                </div>
              </div>
              {/*<!-- /.area_wrap_chil -->*/}

              <div class="area_bnr">
                <a href="https://www.slingbelt.com/" class="view_pc top2401__common_hover01" target="_blank" rel="noreferrer">
                  <Datoimagename name="top2401_bnr_online_pc.jpg" alt="アヴィエラン メーカー直営オンラインストア" folder="topimage" />
                </a>
              </div>
              {/*<!-- /.area_bnr -->*/}
            </div>
            {/*<!-- /.area_wrap_large -->*/}
          </div>
          {/*<!-- /.top2401_contswrap01 -->*/}
        </div>
        {/*<!-- /.sec_business -->*/}
      </section>

      <section>
        <div class="sec_news">
          <div class="background_img"></div>

          <div class="top2401_contswrap01">
            <div class="top2401__common_hx01">
              <div class="area_warp cl_news">
                <div class="box_hx">
                  <h2>
                    <span class="char_first">ニ</span>
                    <span class="char_rest">ュースリリース</span>
                  </h2>
                </div>
                <div class="box_sub">
                  アヴィエランの新着情報
                  <p class="inr_btn">
                    <Link to="/news/">
                      <div class="top2401__common_link02">
                        ニュースの一覧はこちら
                        <span class="img_arrow">
                          <img src="https://www.datocms-assets.com/45449/1702951185-top2401_img_arrow01.svg" />
                        </span>
                      </div>
                    </Link>
                  </p>
                </div>
              </div>
              {/*<!-- ↓↓↓/.top2401__common_hx01 -->*/}
            </div>

            <div class="area_wrap_large">
              {newsData.map((e, index) => {
                // const tolink = "color_" + e.category.slug
                if (e.url) {
                  console.log(image);
                  console.log("test" + image[0][0].src.indexOf("23bg"));
                  console.log(image.length);
                  //console.log(e);
                  return (
                    <div class="box_item" key={index}>
                      <div class="data">{e.data}</div>
                      <div class="box_wrap">
                        <div class="inr_wrap">
                          <div class="inr_tag">
                            <div class="tag">{e.category.title}</div>
                          </div>
                          <div class="ir_text">{e.title}</div>
                        </div>
                        {/*<!-- /.inr_wrap -->*/}
                        <div class="inr_btn">
                          <Link to={e.url}>
                            <div class="top2401__common_link02">
                              もっと詳しく
                              <span class="img_arrow">
                                <img src="https://www.datocms-assets.com/45449/1702951185-top2401_img_arrow01.svg" />
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                      {/*<!-- ↓↓↓/.box_item -->*/}
                    </div>
                  );
                } else {
                  return (
                    <div class="box_item" key={index}>
                      <div class="data">{e.data}</div>
                      <div class="box_wrap">
                        <div class="inr_wrap">
                          <div class="inr_tag">
                            <div class="tag">{e.category.title}</div>
                          </div>
                          <div class="ir_text">{e.title}</div>
                        </div>
                        {/*<!-- /.inr_wrap -->*/}
                      </div>
                      {/*<!-- ↓↓↓/.box_item -->*/}
                    </div>
                  );
                }
              })}
            </div>
            {/*<!-- /.area_wrap_large -->*/}
          </div>
          {/*<!-- /.top2401_contswrap01 -->*/}
        </div>
        {/*<!-- /.sec_news -->*/}
      </section>

      <DatoIntellectualproperty />

      <section>
        <div class="sec_bnrA">
          <div class="area_wrap">
            <Link to="/car-lashing/">
              <div className="view_pc_this">
                <Datoimagename name="top2401_bnr_hineri_pc.jpg" alt="カーラッシング　世界初の「ヒネリフック」で特許を取得　詳しくはこちら" folder="topimage" />
              </div>
              <div className="view_sp_this">
                <Datoimagename name="top2401_bnr_hineri_sp.jpg" alt="カーラッシング　世界初の「ヒネリフック」で特許を取得　詳しくはこちら" folder="topimage" />
              </div>
            </Link>
          </div>
        </div>
        {/*<!-- /.sec_bnrA -->*/}
      </section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allDatoCmsNews(limit: 6, sort: { order: DESC, fields: data }) {
      nodes {
        title
        url
        data(formatString: "YYYY/MM/DD")
        category {
          title
        }
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        pc: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
        sp: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "1200" }) {
          ...GatsbyDatoCmsFluid
        }
        news: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "800" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      contentsimg {
        url
        pc: fluid(maxWidth: 1040, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
        sp: fluid(maxWidth: 1040, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "1200" }) {
          ...GatsbyDatoCmsFluid
        }
        news: fluid(maxWidth: 1040, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "800", h: "800" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
